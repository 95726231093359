import React from "react"
//mport { PDFViewer } from "@react-pdf/renderer"
import Page from "../components/common/page"
//import MyDocumentView from "../components/page-components/forms/repair-form/generatePdf"

const Test = () => {
  return <Page title="page404"></Page>
}

export default Test
